.App {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  background-color: #232f3e;
  color: white;
  padding: 1rem;
  text-align: center;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sticky-container {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}

.filters-container {
  background-color: white;
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filter-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 23%;
}

.filter-group label {
  margin-bottom: 0.5rem;
}

.filter-group select,
.filter-group input[type="text"] {
  padding: 0.5rem;
}

.filter-group.ip-version {
  flex-direction: row;
  align-items: center;
}

.filter-group.ip-version > label {
  margin-right: 1rem;
}

.filter-group.ip-version > div {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.reset-button {
  align-self: flex-end;
  padding: 0.5rem 1rem;
  background-color: #232f3e;
  color: white;
  border: none;
  cursor: pointer;
}

.results-info {
  margin: 1rem 0;
}

.table-container {
  flex-grow: 1;
  overflow-y: auto;
  border-top: 1px solid #ddd;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #f2f2f2;
}

th, td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  cursor: pointer;
  user-select: none;
}

th:hover {
  background-color: #e0e0e0;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.loading, .no-more-results {
  text-align: center;
  padding: 1rem;
}

footer {
  margin-top: 1rem;
  text-align: center;
  padding: 1rem;
  background-color: #232f3e;
  color: white;
}

@media (max-width: 768px) {
  .filter-group {
    width: 48%;
  }
}